import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article06082020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              S&T AG DEFIES THE CORONA CRISIS WITH A SUCCESSFUL Q2
            </h1>
            <p className="bullet">
              Sales growth of 8% to EUR 268.6 million (previous year: EUR 248.7
              million)
            </p>
            <p className="bullet">
              {" "}
              EBITDA increase in Q2 from 16% to EUR 27.0 million (previous year:
              EUR 23.2 million)
            </p>
            <p className="bullet">
              {" "}
              Operating cash flow in H1 significantly improved by EUR 38.9
              million to EUR 27.2 million (previous year: minus EUR 11.7
              million)
            </p>
            <p className="bullet">
              Order backlog increased to EUR 910 million as of June 30, 2020
              (December 31, 2019: EUR 841 million)
            </p>
            <p className="bullet">
              2020 guidance confirmed: sales and EBITDA of at least EUR 1,150
              million and EUR 115 million respectively
            </p>
            <p>
              The technology group S&T AG (ISIN: AT0000A0E9W5, WKN: A0X9EJ,
              ticker symbol: SANT) successfully closed the first half of 2020
              with the 46th record quarter in a row. In a very difficult
              economic environment characterized by the corona pandemic, S&T AG
              impressed with its strong key figures: At EUR 268.6 million, sales
              were kept at the Q1 / pre-Corona level and by 8 compared to the
              previous year % increase. EBITDA increased to EUR 27.0 million in
              the 2nd quarter of 2020 (previous year: EUR 23.2 million). The
              gross margin increased - due to the further increased technology
              and software share - to 39.3% compared to 36.8% in the first half
              of 2019. The "PEC program" established in 2019 continues to have
              an effect and contributes to an improvement in operating cash flow
              in the first half of the year by an impressive EUR 38.9 million to
              EUR 27, 2 million (previous year: minus EUR 11.7 million).
              Earnings per share (undiluted) increased in the second quarter by
              18% to EUR 16 cents (previous year: EUR 13 cents).
            </p>
            <p>
              S&T has shown itself to be the winner of the Corona crisis to
              date. In general, technology groups offer support in times of
              crisis with their products and the right technical equipment to
              make everyday life easier - whether with video conference systems,
              home workstations or e-learning solutions. S&T meets this
              increased demand and achieves new record values in the areas of
              "Medical" and "Public Business". This cushioned the slump in the
              “Avionics” segment. The order backlog of S&T AG confirms this
              development and, despite the crisis, continued to grow in the 2nd
              quarter. The order book is now at EUR 910 million as of June 30,
              2020, EUR 68 million better than at the beginning of the year.
            </p>
            <p>
              With its high liquidity, financially strong and crisis-proof
              position, the S&T Group can react quickly to acquisition
              opportunities that arise during the crisis. In July, S&T was able
              to use this advantage and further improve the product portfolio
              and the competitive situation with the strategic acquisitions of
              Iskratel and CITYCOMP.
            </p>
            <p>
              Hannes Niederhauser, CEO: “Despite the ongoing crisis, our outlook
              is positive. We made good use of our cards during the crisis,
              benefiting in several areas and therefore see ourselves as clear
              beneficiaries of the Corona crisis. I look forward to further
              successful acquisitions in the coming quarters. We will definitely
              achieve our goal for 2020 of EUR 1,150 million and EUR 115 million
              EBITDA and are reaffirming our medium-term target for 2023 of EUR
              2 billion in sales with an EBITDA of EUR 220 million "{" "}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article06082020;
